<template>
  <div class="history">
    <div class="history__header">
      <img
        class="history__header_background"
        v-webp="require('@/assets/images/history/history-main-photo.jpg')"
        src="@/assets/images/history/history-main-photo.jpg"
        alt="Grotta House History"
      />
      <div class="history__header_container">
        <h1 class="history__header_container-title">History of The Grotta’s House</h1>
        <span class="history__header_container-text">scroll to discover</span>
      </div>
    </div>

    <div class="history__body">
      <section class="section sketch">
        <div class="section-description">
          <span class="section-description__text">
            The Grottas lived in Maplewood, New Jersey from the 1950s. On the outside it was a
            standard colonial in a leafy neighborhood. Inside, it was re-envisioned, reflecting
            Sandy’s keen design eye, featuring custom furniture and a maple staircase by Joyce and
            Edgar Anderson and artwork by William Wyman, Rudy Autio, Toshiko Takaezu and others.
          </span>
        </div>
        <YearLine year="1984" position="right" />
        <img
          class="section-image"
          v-webp="require('@/assets/images/history/original-sketch-1.png')"
          src="@/assets/images/history/original-sketch-1.png"
          alt="Grotta residence hand sketch"
          decoding="async"
          loading="lazy"
        />
        <span class="image-description">Grotta residence original hand sketch</span>
        <div class="sketch__container">
          <img
            class="sketch__container-image"
            v-webp="require('@/assets/images/history/original-sketch-2.png')"
            src="@/assets/images/history/original-sketch-2.png"
            alt="Grotta residence sketch"
            decoding="async"
            loading="lazy"
          />
          <img
            class="sketch__container-image"
            v-webp="require('@/assets/images/history/original-sketch-3.png')"
            src="@/assets/images/history/original-sketch-3.png"
            alt="Grotta residence sketch"
            decoding="async"
            loading="lazy"
          />
        </div>
        <span class="sketch__description">
          During the latter part of the 1980’s, the Grottas chose Richard Meier to design a new
          home, set amid seven acres of rural landscape in Harding Township, New Jersey.
        </span>
      </section>

      <YearLine class="year-line" year="1986" />
      <section class="section section--no-padding building">
        <img
          class="section-image"
          v-webp="require('@/assets/images/history/building.jpg')"
          src="@/assets/images/history/building.jpg"
          alt="Grotta residence building"
          decoding="async"
          loading="lazy"
        />
        <span class="image-description">Lou Grotta, Sandy Grotta, Tracy Tell</span>
      </section>

      <section class="section plan">
        <div class="section-description">
          <span class="section-description__text">
            The double geometry shapes the house; a partial circle is inscribed in a partial square.
            It is a complex structure whether viewed from a distance or moving through the
            three-level interior. An unconventional piercing of the walls invites the passage of
            lights, integrated to the whole of the structure by a grid of large, square, white
            tiles.
          </span>
        </div>
        <img
          class="plan__image"
          v-webp="require('@/assets/images/history/plan.jpg')"
          src="@/assets/images/history/plan.png"
          alt="Grotta residence drawing"
          decoding="async"
          loading="lazy"
        />

        <span class="plan__description">
          “Meier uses elements of architecture as framing devices, and he multiplies the frames,
          even creating frames within frames, so that the viewer is not locked into a single fixed
          position but enticed into circulating through the forest of framing elements.”<br />
          - Joseph Giovannini
        </span>
      </section>

      <YearLine class="year-line" year="2015" />
      <section class="section section--no-padding finished">
        <img
          class="section-image"
          v-webp="require('@/assets/images/history/finished.jpg')"
          src="@/assets/images/history/finished.jpg"
          alt="Grotta residence"
          decoding="async"
          loading="lazy"
        />
        <span class="image-description">Lou Grotta, Richard Meier</span>
      </section>

      <section class="section architect" @keydown.esc="closeVideo">
        <div class="section-description">
          <span class="section-description__text">
            The Grotta House operates as an extension into the landscape, says Richard Meier. “[I]ts
            strong formal geometry anchors the house to the site and is necessary to modulate the
            fluid relationship between interior and exterior spaces.”
          </span>
        </div>
        <BaseButton
          class="button--black-grey button--oval"
          text="Play Grotta House Video"
          @click.native="isVideoOpen = true"
        />

        <div class="pop-up" :class="{ 'pop-up--visible': isVideoOpen }">
          <IconClose color="white" class="pop-up__button" @click.native="closeVideo" />

          <video
            ref="video"
            class="pop-up__video"
            controls
            playsinline
            @play="play = true"
            @pause="play = false"
            @seeking="play = true"
          >
            <source src="@/assets/video/GrottaHouse_Web.mp4" type="video/mp4" />
          </video>
        </div>
      </section>

      <div class="navigation">
        <span class="navigation__title">Next: Interview</span>
        <div class="navigation__buttons-box">
          <BaseButton
            class="button--black-grey button--oval"
            text="Outside"
            @click="openExteriorsPage"
          />
          <BaseButton
            class="button--black-grey button--oval"
            text="Interview"
            @click="openInterviewPage"
          />
          <BaseButton
            class="button--outline button--oval"
            text="Back to Home"
            @click="openHomePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import YearLine from "@/components/history/HistoryYearLine.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: {
    YearLine,
    BaseButton,
    IconButton,
    IconClose,
  },

  data() {
    return {
      isVideoOpen: false,
      play: false,
    };
  },

  head: {
    title: function () {
      return {
        inner: "GH - House History",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {},

  created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "About",
        to: "/about",
      },
      {
        title: "House History",
        to: "/about/the-history",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),

    openExteriorsPage() {
      this.$router.push({ name: "Exteriors", params: { id: 1 } });
    },

    openInterviewPage() {
      this.$router.push({ path: "/interview" });
    },

    openHomePage() {
      this.$router.push({ path: "/" });
    },

    closeVideo() {
      this.stopVideo();
      this.isVideoOpen = false;
    },

    stopVideo() {
      this.play = false;
      this.$refs.video.load();
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  font-size: 2.1rem;
  line-height: 1.66;
  background-color: $white;
  @media only screen and (max-width: $xxs) {
    font-size: 1.8rem;
    line-height: 1.55;
  }

  &__header {
    &_background {
      position: absolute;
      object-fit: cover;
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
    }

    &_container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100vh;
      margin: 0 16.4rem 17rem;
      line-height: 4.4rem;
      @media only screen and (max-width: $xxs) {
        margin: 0 2.8rem 10.5rem;
      }

      &-title {
        font-family: $fontBodoni;
        color: $white;
        font-size: 6.2rem;
        line-height: 1;
        font-weight: 600;
        margin-top: 2rem;
        @media only screen and (max-width: $xxs) {
          font-size: 4.9rem;
          font-weight: 500;
          letter-spacing: -0.82px;
          line-height: 1.2;
          margin-top: 0;
        }
      }

      &-text {
        font-family: $fontBodoni;
        color: $white;
        font-size: 1.8rem;
        font-weight: 600;
        width: 100%;
        margin-top: 9rem;
        margin-bottom: 2.3rem;
        text-align: center;
        @media only screen and (max-width: $lg) {
          margin-top: 3rem;
        }
        @media only screen and (max-width: $xxs) {
          margin-top: 4.7rem;
        }
      }
    }
  }

  &__body {
    background-color: $white;

    .year-line {
      margin-top: 10rem;
      @media only screen and (max-width: $xxs) {
        margin-top: 4rem;
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 16.4rem;
      @media only screen and (max-width: $md) {
        padding: 0rem 10.4rem;
      }
      @media only screen and (max-width: $xxs) {
        padding: 0rem 2.8rem;
      }

      &--no-padding {
        padding: 0;

        @media only screen and (max-width: $xxs) {
          padding: 0rem 2.8rem;
        }
      }

      .section-description {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &__text {
          font-family: $fontBodoni;
          color: $lightBlack;
          font-size: 2.7rem;
          font-weight: 500;
          letter-spacing: -0.45px;
          line-height: 1.4;
          max-width: 80%;
          @media only screen and (max-width: $lg) {
            width: 95%;
          }
          @media only screen and (max-width: $md) {
            max-width: 100%;
          }
          @media only screen and (max-width: $xxs) {
            font-size: 2.2rem;
          }
        }
      }

      .section-image {
        width: 100%;
      }

      .image-description {
        font-family: $fontBodoni;
        font-size: 2.2rem;
        font-weight: 500;
        @media only screen and (max-width: $xxs) {
          font-size: 1.8rem;
        }
      }

      &.sketch {
        .section-description__text {
          margin-bottom: 3.3rem;
        }
        .sketch {
          &__container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 12.3rem;
            @media only screen and (max-width: $lg) {
              flex-direction: column;
              align-items: center;
            }
            @media only screen and (max-width: $xxs) {
              margin-top: 6.4rem;
            }

            &-image {
              height: 58.1rem;
              width: 42%;
              @media only screen and (max-width: $lg) {
                width: 65%;
              }
              @media only screen and (max-width: $md) {
                width: 70%;
              }
              @media only screen and (max-width: $xxs) {
                height: 27.5rem;
                width: 100%;
              }
            }

            :last-child {
              margin-top: 9.5rem;
              @media only screen and (max-width: $xxs) {
                margin-top: 3.3rem;
              }
            }
          }

          &__description {
            display: inline-block;
            text-align: center;
            margin-top: 14rem;
            width: 55%;
            font-family: $fontBodoni;
            color: $lightBlack;
            font-size: 2.7rem;
            font-weight: 500;
            letter-spacing: -0.45px;
            line-height: 1.4;
            @media only screen and (max-width: $lg) {
              width: 100%;
            }
            @media only screen and (max-width: $xxs) {
              margin-top: 9rem;
              font-size: 2.2rem;
            }
          }
        }
      }

      &.building {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &.plan {
        margin-top: 11.4rem;
        @media only screen and (max-width: $xxs) {
          margin-top: 6.9rem;
        }

        .plan__image {
          margin-top: 45px;
          @media only screen and (max-width: $xxs) {
            height: 300px;
            max-width: none;
          }
        }

        .plan__description {
          display: inline-block;
          text-align: center;
          width: 68%;
          margin-top: 45px;
          font-family: $fontBodoni;
          color: $lightBlack;
          font-size: 2.7rem;
          font-weight: 500;
          letter-spacing: -0.45px;
          line-height: 1.4;
          @media only screen and (max-width: $lg) {
            width: 100%;
          }
          @media only screen and (max-width: $xxs) {
            font-size: 2.2rem;
          }
        }
      }

      &.architect {
        align-items: flex-start;
        margin-top: 9.8rem;
        @media only screen and (max-width: $xxs) {
          margin-top: 6.9rem;
        }

        .button--oval {
          margin-top: 8.2rem;
          font-family: $fontBodoni;
        }

        .pop-up {
          position: fixed;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100%;
          opacity: 0;
          transform: scale(0);
          pointer-events: none;
          transition: all 0.5s;
          z-index: 999;

          &--visible {
            opacity: 1;
            pointer-events: all;
            transform: scale(1);
            transition: all 0.5s;
          }

          &__button {
            position: absolute;
            top: 30px;
            right: 30px;
            height: 2.5rem;
            width: 2.5rem;
            z-index: 999;
            cursor: pointer;
          }

          &__video {
            position: absolute;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .navigation {
      display: flex;
      flex-direction: column;
      margin: 13rem 0 10.4rem;
      padding-left: 25%;
      @media only screen and (max-width: $xxs) {
        margin: 7.6rem 0 8.9rem;
        padding-left: 1.6rem;
      }

      &__title {
        font-family: $fontBodoni;
        font-size: 3.8rem;
        line-height: 1.25;
        font-weight: 600;
        margin: 0 0 5rem 1.2rem;
        @media only screen and (max-width: $xxs) {
          font-size: 2.5rem;
          font-weight: 500;
          letter-spacing: -0.42px;
          margin: 0 0 3rem 1.4rem;
        }
      }

      &__buttons-box {
        display: flex;
        align-items: center;
        @media only screen and (max-width: $sm) {
          flex-wrap: wrap;
        }

        &::after {
          content: "";
          background-color: $black;
          height: 1px;
          flex-grow: 100;
          @media only screen and (max-width: $sm) {
            margin-top: 16px;
          }
        }

        .button--oval {
          margin-left: 12px;
          font-family: $fontBodoni;
          @media only screen and (max-width: $sm) {
            margin-top: 1.7rem;
            min-width: 170px;
          }
        }

        .button--outline {
          font-weight: 700;
        }
      }
    }
  }
}
</style>
